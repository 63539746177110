import type { AccentHue } from "@repo/fika-design-system/color"
import type { IconName } from "@repo/fika-design-system/icon"
import { type JSX, type ParentProps, createMemo, splitProps } from "solid-js"
import { Dynamic } from "solid-js/web"
import { Box } from "./box"
import { buttonRecipe, iconRecipe } from "./button.css"
import { Icon } from "./icon"

type Tag = "button" | "a"
type GenericButtonProps = {
	hue: AccentHue
	kind?: "ghost" | "darkGhost" | "normal"
	iconName: IconName
	class?: string
} & ParentProps
function GenericButton(props: GenericButtonProps & { tag: Tag }) {
	const [cProps, nProps] = splitProps(props, [
		"hue",
		"kind",
		"iconName",
		"children",
		"class",
		"tag",
	])
	const className = createMemo(() =>
		[buttonRecipe({ hue: cProps.hue, kind: cProps.kind }), cProps.class]
			.filter(Boolean)
			.join(" "),
	)

	return (
		<Dynamic component={cProps.tag} {...nProps} class={className()}>
			<Box gap="4" flexDirection="row" alignItems="center">
				<Icon
					name={cProps.iconName}
					class={iconRecipe({
						hue: cProps.hue,
					})}
				/>
				{cProps.children}
			</Box>
		</Dynamic>
	)
}

type LinkButtonProps = JSX.AnchorHTMLAttributes<HTMLAnchorElement> &
	GenericButtonProps
export function LinkButton(props: LinkButtonProps) {
	return <GenericButton {...props} tag={"a" as Tag} />
}

type ButtonProps = JSX.ButtonHTMLAttributes<HTMLButtonElement> &
	GenericButtonProps
export function Button(props: ButtonProps) {
	return <GenericButton {...props} tag={"button" as Tag} />
}
