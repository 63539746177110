import { cookieDomain, cookieSameSite } from "@/context/hanko"
import { useSearchParams } from "@solidjs/router"
import { register } from "@teamhanko/hanko-elements"
import { Show, onMount } from "solid-js"
import ExtensionAuth from "./extensionAuth"

type GlobalJsx = JSX.IntrinsicElements

declare module "solid-js" {
	namespace JSX {
		interface IntrinsicElements {
			"hanko-auth": GlobalJsx["hanko-auth"]
		}
	}
}

const EXTENSION_ORIGIN = "extension"

export default function Auth() {
	const hankoUrl = import.meta.env.VITE_FIKA_HANKO_API_URL
	const [params, _] = useSearchParams()

	onMount(() => {
		if (params.origin === EXTENSION_ORIGIN) return

		register(hankoUrl, {
			cookieSameSite: cookieSameSite(),
			cookieDomain: cookieDomain(),
		}).catch((error) => {
			console.error(error)
			// handle error
		})
	})

	return (
		<Show
			when={params.origin !== EXTENSION_ORIGIN}
			fallback={<ExtensionAuth />}
		>
			<hanko-auth />
		</Show>
	)
}
