/* @refresh reload */
import * as Sentry from "@sentry/solid"
import "./global.css"

import "@fontsource-variable/literata/standard.css"
import "@fontsource-variable/literata/standard-italic.css"
import "@fontsource-variable/playpen-sans"
import "@fontsource/geist-sans/400.css"
import "@fontsource/geist-sans/500.css"
import "@fontsource-variable/bricolage-grotesque"

import "@/designSystem/hanko.css"
import { Route, Router } from "@solidjs/router"
import { lazy } from "solid-js"
import { render } from "solid-js/web"

import { RootLayout } from "@/designSystem/layouts/rootLayout"
import { isValidUrl } from "@/lib/slug"
import Auth from "./pages/auth"

const Main = lazy(() => import("./pages/main"))
const Dashboard = lazy(() => import("./pages/dashboard/page"))
const Stories = lazy(() => import("./pages/stories/page"))
const Posts = lazy(() => import("./pages/posts/page"))
const Publications = lazy(() => import("./pages/publishers/page"))
const StoriesSettings = lazy(() => import("./pages/stories/settings"))
const PublishersSettings = lazy(() => import("./pages/publishers/settings"))
const PostsSettings = lazy(() => import("./pages/posts/settings"))
const AddNewPost = lazy(() => import("./pages/posts/postForm"))
const PostSettings = lazy(() => import("./pages/posts/postSettings"))
const Settings = lazy(() => import("./pages/settings/page"))
const Share = lazy(() => import("./pages/stories/share"))

const root = document.getElementById("root")

function NotFound() {
	return <div>Not found</div>
}

const slugFilters = {
	storyId: isValidUrl,
	postId: isValidUrl,
	publisherId: isValidUrl,
}

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_ENV,
	integrations: [],
})

render(
	() => (
		<Router root={RootLayout}>
			<Route path="/join" component={Auth} />
			<Route path="/" component={Main}>
				<Route path="/">
					<Route component={Dashboard}>
						<Route path="/" />
						<Route path="/stories/:storyId" matchFilters={slugFilters} />
						<Route path="/feeds/:publisherId" matchFilters={slugFilters}>
							<Route path="/" />
							<Route path="/:storyId" matchFilters={slugFilters} />
						</Route>
					</Route>

					<Route path="/bookmarks">
						<Route path="/share" component={Share} />
						<Route path="/add" component={StoriesSettings} />
						<Route path="/:filter/:query?">
							<Route component={Stories}>
								<Route path="/" />
								<Route path="/story/:storyId" matchFilters={slugFilters} />
							</Route>
						</Route>
					</Route>

					<Route path="/feeds">
						<Route path="/subscribe" component={PublishersSettings} />
						<Route path="/:filter/:query?">
							<Route component={Publications}>
								<Route path="/" />
								<Route path="/feed/:publisherId" matchFilters={slugFilters}>
									<Route path="/" />
									<Route path="/:storyId" matchFilters={slugFilters} />
								</Route>
							</Route>
						</Route>
					</Route>

					<Route path="/blog">
						<Route path="/settings" component={PostsSettings} />
						<Route path="/new" component={AddNewPost} />
						<Route path="/:filter/:query?">
							<Route path="/post/:postId/settings" component={PostSettings} />
							<Route component={Posts}>
								<Route path="/" />
								<Route path="/post/:postId" matchFilters={slugFilters} />
							</Route>
						</Route>
					</Route>

					<Route path="/settings">
						<Route path="/" component={Settings} />
					</Route>
				</Route>
				<Route path="*404" component={NotFound} />
			</Route>
		</Router>
	),
	root!,
)
