import type { Locales } from "@/i18n/i18n-types"
import { loadLocaleAsync } from "@/i18n/i18n-util.async"
import { detectLocale, navigatorDetector } from "typesafe-i18n/detectors"

const fallbackLocale = "en"
const availableLocales = ["en", "es"]

export function userLocale() {
	return detectLocale(
		fallbackLocale,
		availableLocales,
		navigatorDetector,
	) as Locales
}

export async function loadLocale(locale: Locales) {
	await loadLocaleAsync(locale)
}
